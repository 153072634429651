import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { ClockPage } from "./pages/Clock";
// import { ApolloPage } from "./pages/Apollo";
import { HomePage } from "./pages/Home";
import { MomPage } from "./pages/Mom";

import "./index.css";

function App() {
  return (
    <Router>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div
              style={{
                display: "flex",
                marginBottom: 40,
              }}
            >
              <Switch>
                <Route
                  path="/"
                  exact
                  component={() => (
                    <img
                      alt="Profile Pic"
                      src="./me.png"
                      style={{
                        flex: "none",
                        height: 80,
                        width: 80,
                        borderRadius: 80,
                      }}
                    />
                  )}
                />
                <Route
                  component={() => (
                    <Link
                      to="/"
                      style={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="Profile Pic"
                        src="./me.png"
                        style={{
                          flex: "none",
                          height: 30,
                          width: 30,
                          borderRadius: 30,
                        }}
                      />
                      <span
                        style={{
                          marginLeft: 10,
                          fontSize: "1rem",
                          // fontWeight: 400,
                        }}
                      >
                        {/* 👋 Hi! I'm Danielle */}
                        Home
                      </span>
                    </Link>
                  )}
                />
              </Switch>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <a className="social-icon" href="https://twitter.com/danimman">
                  <i
                    className="fab fa-twitter-square"
                    style={{ fontSize: 30, marginRight: 12 }}
                  ></i>
                </a>
                <a className="social-icon" href="https://github.com/daniman">
                  <i
                    className="fab fa-github-square"
                    style={{ fontSize: 30, marginRight: 12 }}
                  ></i>
                </a>
                <a className="social-icon" href="https://medium.com/@danimman">
                  <i
                    className="fab fa-medium"
                    style={{ fontSize: 30, marginRight: 12 }}
                  ></i>
                </a>
                <a
                  className="social-icon"
                  href="https://www.linkedin.com/in/danielleman/"
                >
                  <i className="fab fa-linkedin" style={{ fontSize: 30 }}></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <Switch>
          <Route path="/mom" component={MomPage} />
          <Route path="/clock" component={ClockPage} />
          {/* <Route path="/apollo" component={ApolloPage} /> */}
          <Route path="/" exact component={HomePage} />
        </Switch>
      </div>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
