import React from 'react';

export const MomPage = () => (
  <>
    <div
      className="row"
      style={{ marginBottom: 32, paddingLeft: 40, paddingRight: 40 }}
    >
      <div
        className="col-lg-6 col-12"
        style={{ fontSize: 24, fontWeight: 700 }}
      >
        Happy Birthday Mom{' '}
        <span role="img" aria-label="Cake Slice">
          🍰
        </span>
        <span role="img" aria-label="Birthday Cake">
          🎂
        </span>
        <span role="img" aria-label="Red Balloon">
          🎈
        </span>
      </div>
      <div
        className="col-lg-6 col-12 number justify-content-lg-end justify-content-start"
        style={{
          position: 'static',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        April 01, 2020
      </div>
    </div>
    <div className="row" style={{ paddingLeft: 40, paddingRight: 40 }}>
      <div className="col-lg-3 col-sm-6 col-12">
        <div style={{ marginBottom: 32 }}>
          <div className="number">01</div>
          <div>Happy birthday to you,</div>
          <div>Happy birthday to you,</div>
          <div>You might be far away,</div>
          <div>But we both still love you.</div>
        </div>

        <div style={{ marginBottom: 32 }}>
          <div className="number">02</div>
          <div>
            You’re turning thirty-six{' '}
            <span role="img" aria-label="Winking Face">
              😉
            </span>
          </div>
          <div>Go get some margarita mix,</div>
          <div>Then take some crazy pics,</div>
          <div>And chill with Netflix.</div>
        </div>

        <div style={{ marginBottom: 32 }}>
          <div className="number">03</div>
          <div>Today is April fools,</div>
          <div>Live by your own rules,</div>
          <div>Just kidding,</div>
          <div>We’re in a global pandemic...</div>
        </div>
      </div>

      <div className="col-lg-3 col-sm-6 col-12">
        <div style={{ marginBottom: 32 }}>
          <div className="number">04</div>
          <div>You’re up in Truckee,</div>
          <div>But you cannot ski,</div>
          <div>We hope you’re passing time,</div>
          <div>Nicely with your honey.</div>
        </div>

        <div style={{ marginBottom: 32 }}>
          <div className="number">05</div>
          <div>If you need TP,</div>
          <div>For your birthday party,</div>
          <div>Good luck finding TP,</div>
          <div>And maybe don’t throw a party.</div>
        </div>

        <div style={{ marginBottom: 32 }}>
          <div className="number">06</div>
          <div>Turns out we’re bad at rhyming,</div>
          <div>But we’re good at whining,</div>
          <div>Thanks for always providing...</div>
          <div>Love and support to us – silver lining?</div>
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <iframe
          title="Dani & Jess sing mom Happy Birthday."
          width="500"
          height="282"
          src="https://www.youtube.com/embed/mMmh2WCrpKI"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div style={{ textAlign: 'right', marginTop: 32 }}>
          <div>
            Lots of{' '}
            <span role="img" aria-label="Red Heart">
              ❤️
            </span>
          </div>
          <div>Jessie & Dani</div>
        </div>
      </div>
    </div>
  </>
);
