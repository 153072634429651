import React from "react";
import { Link } from "react-router-dom";

export const HomePage = () => (
  <>
    <div className="row">
      <div className="col-lg-8 offset-lg-2 col-12">
        <h3>
          Hi! I'm Danielle, an engineer and manager in San Francisco.{" "}
          <span role="img" aria-label="Golden Gate Bridge">
            🌉
          </span>
        </h3>
        <p>
          For the last several years I've been helping make{" "}
          <a href="https://graphql.org/">GraphQL</a> easier to build with and
          use at <a href="https://www.apollographql.com">Apollo</a>. I love
          building products for the web, solving problems with craftsmanship and
          code.
        </p>
        <p>
          In my time at Apollo I've been a friend, a manager, a developer, a
          product manager, a recruiter, an advocate, and more. At the end of the
          day,{" "}
          <b>
            I just want to make tools that help people and feel great to use
          </b>
          . I care much more about the people I'm working with than the
          day-to-day specifics of my job.
        </p>
      </div>
    </div>
    <div className="row" style={{ marginTop: 40 }}>
      <div className="col-lg-8 offset-lg-2 col-12">
        <div>
          <b>Some talks I've done:</b>
        </div>
        <div>
          <a href="https://stackoverflow.blog/2022/03/18/mcdonalds-is-to-chipotle-what-rest-apis-are-to-graphql-ep-424/?utm_source=twitter&utm_medium=social&utm_campaign=so-podcast&utm_content=dmannpod">
            Stack Overflow Podcast
          </a>{" "}
          | March 18, 2022
        </div>
        <div>
          <a href="https://youtu.be/f12XvZ9CHEs">
            The Secret to Successful Graph Onboarding
          </a>{" "}
          | GraphQL Summit 2021
        </div>
        <div>
          <a href="https://youtu.be/ua_8cECclT4">GraphQL is for Everyone</a> |
          GraphQL Galaxy 2020
        </div>
        <div>
          <a href="https://youtu.be/ImudUVWINXo?t=275">Querying Data Faster</a>{" "}
          | GraphQL Summit 2020
        </div>
        {/* <div>
          <a href="https://www.youtube.com/watch?v=DPN47OoQADc">
            Apollo Studio Preview
          </a>{" "}
          | Casual appearance on the Apollo Twitch stream
        </div> */}
        <div>
          <a href="https://www.youtube.com/watch?v=q-eJvp9CMY8">
            The GraphQL Developer Experience
          </a>{" "}
          | GraphQL Summit 2019
        </div>
        <div>
          <a href="https://www.youtube.com/watch?v=XRKUB0QCRUU">
            Devtools for GraphQL
          </a>{" "}
          | GraphQL Summit 2017{" "}
          <span style={{ fontSize: "0.8rem", opacity: 0.6 }}>(lightning)</span>
        </div>
        <div>
          <a href="https://conferences.oreilly.com/fluent/fl-ca-2017/public/schedule/detail/58715.html">
            Schema-First Development with GraphQL
          </a>{" "}
          | O'Reilly Fluent Conference 2017
        </div>
        <div>
          <a href="https://www.youtube.com/watch?v=NqIHHo5nkaI">
            Devtools for GraphQL
          </a>{" "}
          | React Europe 2017{" "}
          <span style={{ fontSize: "0.8rem", opacity: 0.6 }}>(lightning)</span>
        </div>
        <div>
          <a href="https://www.youtube.com/watch?v=RCIRZr0RRt8">
            Navigating Your Transition to GraphQL
          </a>{" "}
          | GraphQL Summit 2016
        </div>
      </div>
    </div>

    <div className="row" style={{ marginTop: 40 }}>
      <div className="col-lg-8 offset-lg-2 col-12">
        <div>
          <b>A few hobby projects:</b>
          <span style={{ marginLeft: 10, fontSize: "0.8rem", opacity: 0.6 }}>
            (most of my spare dev time is spent on Apollo things)
          </span>
        </div>
        <div>
          <a href="https://mission.apollo.dev">Operation Homeworld</a> | A
          GraphQL-based puzzlehunt complete with riddles, servers, and query
          writing.
        </div>
        <div>
          <a href="https://splendoor.netlify.com">Splendor</a> | A web version
          of the{" "}
          <a href="https://en.wikipedia.org/wiki/Splendor_(game)">board game</a>
          , made for friends during the COVID-19 pandemic.{" "}
          <a href="https://github.com/daniman/splendor-client">
            <i className="fab fa-github"></i>
          </a>
        </div>
        <div>
          <Link to="/clock">Analog Clock</Link> | A 24-hour clock that
          represents timezones across the world.{" "}
          <a href="https://github.com/daniman/analog-clock">
            <i className="fab fa-github"></i>
          </a>
        </div>
        <div>
          <a href="https://www.youtube.com/watch?v=0pEuiGU-wx0">Grouper</a> | MIT 6.813 class project that won the Best Final Project award.{" "}
          <a href="https://github.com/daniman/grouper">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </div>
    </div>
  </>
);
