import React from "react";

import Clock from "./clock/Clock";

export const ClockPage = () => (
  <div className="row">
    <div className="col-lg-3 offset-lg-2">
      <p>
        <a href="https://github.com/daniman/analog-clock">
          github.com/daniman/analog-clock
        </a>
      </p>
      <p>
        A clock that I made for the Apollo team, to visualize our timezones more
        easily when scheduling meetings. This clock is built entirely with CSS
        rotations and a little bit of JavaScript for the ticking hands.
      </p>
    </div>
    <div className="col-lg-5" style={{ marginBottom: 40 }}>
      <div
        style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}
      >
        <Clock size={420} />
      </div>
    </div>
  </div>
);
